<template>
  <div>
    <v-navigation-drawer v-model="drawer" app temporary width="100%" right height="100%">
      <template #prepend>
        <v-card flat tile class="pa-4 pt-5 d-flex" :style="`background-color: ${ $vuetify.theme.dark ? '#181818' : systemDetails.themecolor}`">
          <v-icon :color="systemDetails.textcolor" left size="20"> mdi-filter </v-icon>
          <span :style="`color:${systemDetails.textcolor || 'white'}`" class="body-1"> {{ $t('filters') }} </span>
          <v-spacer></v-spacer>
          <v-btn color="success mr-5" class="text-capitalize" @click="applyFilters" x-small> {{ $t('apply') }} </v-btn>
          <v-btn icon x-small class="mr-5" :color="systemDetails.textcolor" :title="$t('removeFilter')" @click="resetFilters">
            <v-icon> mdi-filter-remove </v-icon>
          </v-btn>
          <v-btn icon x-small :color="systemDetails.textcolor" :title="$t('close')" @click="drawer = false;">
            <v-icon> mdi-close-circle </v-icon>
          </v-btn>
        </v-card>
        <v-divider></v-divider>
      </template>
      <template>
        <v-form ref="filterform">
          <div class="pa-2 pt-0 py-2 filters-body-container overflow-y-auto" :style="`height: 100%; background: ${ $vuetify.theme.dark ? '#1e1e1e' : '#ffffff' }`">
            <template v-for="(field, fieldIndex) in allfields">
              <v-layout row wrap align-center class="my-3 mx-5" :key="fieldIndex" v-if="field && ![9, 10, 11, 19].includes(field.type) && field.value !== 'isanonymous'">
                <v-flex xs1>
                  <v-checkbox
                    v-model="field.selectBox"
                    dense
                    hide-details
                    class="mt-0"
                    @change="fieldCheckChangeHandler({ field, fieldIndex })"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs11 class="pt-1 px-2">
                  <span :class="['subtitle-1 font-weight-medium', $vuetify.theme.dark ? 'grey--text text--lighten-2' : '']"> {{ $t(field.text) }} </span>
                  <v-menu bottom offset-y v-if="field.selectBox">
                    <template #activator="{ on, attrs }">
                      <div class="d-inline-flex" v-on="on" v-bind="attrs">
                        <span :class="['caption px-1 font-weight-bold', $vuetify.theme.dark ? '' : 'primary--text text--darken-4']">
                          {{ getSelectedType({ field }) }}
                        </span>
                        <v-icon x-small color="primary"> mdi-arrow-down-drop-circle </v-icon>
                      </div>
                    </template>
                    <v-list dense class="py-1">
                      <v-list-item v-for="(item, index) in getListOfFilters(field)" :key="index"
                      @click="$set(field, 'condition', item)" dense>
                        <v-list-item-content> {{ item.text }} </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-flex>
                <v-flex xs1 v-if="field.selectBox"></v-flex>
                <v-flex xs11 class="px-2" v-if="field.selectBox">
                  <template v-if="field.isSubModule">
                      <v-autocomplete :items="field.default_value.options" item-text="data.name" item-value="_id" v-model="field.inputValue" dense hide-details multiple
                      ref="filterSearchText" @keydown="preventSpecialCharacters($event)" @keyup="$event.keyCode !== 13 ? loadSelectFieldValues({field, module: field.allValues.module }) : ''">
                        <template v-slot:selection="{ item, index }">
                          <span v-if="index === 0">{{ item.data.name }}</span>
                          <span
                            v-if="index === 1"
                            class="grey--text caption pt-1 ml-2"
                          > (+{{ field.inputValue.length - 1 }} others)
                          </span>
                        </template>
                      </v-autocomplete>
                    </template>
                  <div class="mt-n2" v-else-if="[1, 2, 12, 13, 14, 18].includes(field.type) || field.type === 5 &&  field.default_value.selectType === 'custom'">
                    <v-text-field dense hide-details v-model="field.inputValue" @keydown.enter="applyFilters"></v-text-field>
                  </div>
                  <div class="mt-n1" v-else-if="[3, 4, 5, 15, 6, 16].includes(field.type)">
                    <!-- autocomplete -->
                    <template v-if="(field.type === 5 && field.default_value.selectType === 'module')">
                      <v-autocomplete dense hide-details :items="field.default_value.options" v-model="field.inputValue"
                      :item-text="getSelectFieldItemTextValue(field).text" :item-value="getSelectFieldItemTextValue(field).value" multiple
                      ref="filterSearchText" clearable deletable-chips :loading="field.loading" :disabled="field.disabled"
                      @keydown="preventSpecialCharacters($event)" @keyup="$event.keyCode !== 13 ? loadSelectFieldValues({ field, fieldIndex }) : ''"
                      :no-data-text="$refs.filterSearchText && $refs.filterSearchText[0] && $refs.filterSearchText[0].lazySearch ? $t('noData') : $t('startTyping')">
                        <template #selection="{ item, index }">
                          <v-chip v-if="index === 0" small>
                            <span> {{ item.data && item.data.name || item.label || item }} </span>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text text-caption">
                            (+{{ field.inputValue.length - 1 }} others)
                          </span>
                        </template>
                      </v-autocomplete>
                    </template>
                    <template v-if="field.type === 5 && field.default_value.selectType === 'default'">
                      <v-select dense hide-details :items="field.default_value.options" v-model="field.inputValue"
                          :item-text="field.allValues.enableoptiontranslations ? val => field.allValues.is_internal ? $t(`${$route.params.name}_${field.allValues.label}_option_${val.value}`) : $t(`${field.allValues.label}_option_${val.value}`) : val => $t(val.label)"
                          :item-value="getSelectFieldItemTextValue(field).value" :multiple="[4, 5].includes(field.type)">
                          <template #selection="{ item, index }" v-if="field.type === 5">
                            <v-chip v-if="index === 0" small>
                              <span> {{ item.data && item.data.name || item.label || item }} </span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text text-caption">
                              (+{{ field.inputValue.length - 1 }} others)
                            </span>
                          </template>
                        </v-select>
                    </template>
                    <template v-else-if="field.type === 15">
                      <v-autocomplete :items="getUsers" item-text="name" item-value="_id" v-model="field.inputValue" dense hide-details multiple>
                        <template v-slot:selection="{ item, index }">
                          <span v-if="index === 0">{{ item.name }}</span>
                          <span
                            v-if="index === 1"
                            class="grey--text caption pt-1 ml-2"
                          > (+{{ field.inputValue.length - 1 }} others)</span>
                        </template>
                      </v-autocomplete>
                    </template>
                    <!-- select -->
                    <template v-else>
                      <template v-if="[6, 16].includes(field.type)">
                        <template v-if="['withinpast', 'withinnext'].includes((field.condition && field.condition.value) ? field.condition.value : field.condition)">
                          <v-text-field dense hide-details v-model="field.within" @keydown="preventSpecialCharacters($event)" @keydown.enter="applyFilters" type="number">
                            <template #append>
                              <span class="pr-2 body-2 mb-n1"> {{ $t('days') }} </span>
                            </template>
                          </v-text-field>
                        </template>
                        <template v-if="field.type === 6">
                          <template v-if="['before', 'after'].includes((field.condition && field.condition.value) ? field.condition.value : field.condition)">
                            <v-menu v-model="field.dateMenu" transition="scale-transition" offset-y min-width="290">
                              <template #activator="{ on }">
                                <v-text-field dense hide-details slot="activator" key="datepicker" :placeholder="userDetails.dateformat" v-model="field.date"
                                  readonly @focus="$set(field, 'inputValue', $formatter.formatDate(field.date, userDetails.dateformat, 'YYYY-MM-DD'))" v-on="on"
                                  append-icon="mdi-calendar">
                                </v-text-field>
                              </template>
                              <v-date-picker color="primary" no-title v-model="field.inputValue"
                              @input="$set(field, 'date', $formatter.formatDate(field.inputValue, 'YYYY-MM-DD', userDetails.dateformat)); field.dateMenu = false"></v-date-picker>
                            </v-menu>
                          </template>
                          <template v-else-if="['between'].includes((field.condition && field.condition.value) ? field.condition.value : field.condition)">
                            <v-layout row align-center class="ma-0" key="between">
                              <v-flex xs6 class="pr-2">
                                <v-menu v-model="field.startDateMenu" transition="scale-transition" offset-y min-width="290">
                                  <template #activator="{ on }">
                                    <v-text-field dense hide-details slot="activator" key="startdatepicker" :placeholder="userDetails.dateformat" v-model="field.startdate"
                                      readonly @focus="$set(field, 'startDateinput', $formatter.formatDate(field.startdate, userDetails.dateformat, 'YYYY-MM-DD'))"
                                      v-on="on" append-icon="mdi-calendar">
                                    </v-text-field>
                                  </template>
                                  <v-date-picker color="primary" no-title v-model="field.startDateinput"
                                    @input="$set(field, 'startdate', $formatter.formatDate(field.startDateinput, 'YYYY-MM-DD', userDetails.dateformat)); field.startDateMenu = false">
                                  </v-date-picker>
                                </v-menu>
                              </v-flex>
                              <v-flex xs6 class="pl-2">
                                <v-menu v-model="field.endDateMenu" transition="scale-transition" offset-y min-width="290">
                                  <template #activator="{ on }">
                                    <v-text-field dense hide-details slot="activator" key="enddatepicker" :placeholder="userDetails.dateformat" v-model="field.enddate"
                                      readonly @focus="$set(field, 'endDateinput', $formatter.formatDate(field.enddate, userDetails.dateformat, 'YYYY-MM-DD'))"
                                      v-on="on" append-icon="mdi-calendar">
                                    </v-text-field>
                                  </template>
                                  <v-date-picker color="primary" no-title v-model="field.endDateinput" :min="field.startdate"
                                    @input="$set(field, 'enddate', $formatter.formatDate(field.endDateinput, 'YYYY-MM-DD', userDetails.dateformat)); field.endDateMenu = false">
                                  </v-date-picker>
                                </v-menu>
                              </v-flex>
                            </v-layout>
                          </template>
                        </template>
                        <template v-else-if="field.type === 16">
                          <template v-if="['before', 'after'].includes((field.condition && field.condition.value) ? field.condition.value : field.condition)">
                            <datetime-picker v-model="field.date" :key="field.condition.value" @input="updateDateTime(fieldIndex)"></datetime-picker>
                          </template>
                          <template v-else-if="['between'].includes((field.condition && field.condition.value) ? field.condition.value : field.condition)">
                            <v-layout row align-center class="ma-0" key="between">
                              <v-flex xs6 class="pr-2">
                                <datetime-picker @updateStartEndDate="updateStartEndDate(fieldIndex)" v-model="field.startdate" key="startDateTime"></datetime-picker>
                              </v-flex>
                              <v-flex xs6 class="pl-2">
                                <datetime-picker @updateStartEndDate="updateStartEndDate(fieldIndex)" v-model="field.enddate" key="endDateTime" enddatetime></datetime-picker>
                              </v-flex>
                            </v-layout>
                          </template>
                        </template>
                      </template>
                      <template v-else>
                        <v-select dense hide-details :items="field.type === 3 ? checkboxFilterItems : field.default_value.options" v-model="field.inputValue"
                        :item-text="getSelectFieldItemTextValue(field).text" :item-value="getSelectFieldItemTextValue(field).value" :multiple="[4, 5].includes(field.type)">
                          <template #selection="{ item, index }" v-if="field.type === 5">
                            <v-chip v-if="index === 0" small>
                              <span> {{ item.data && item.data.name || item.label || item }} </span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text text-caption">
                              (+{{ field.inputValue.length - 1 }} others)
                            </span>
                          </template>
                        </v-select>
                      </template>
                    </template>
                  </div>
                  <div class="mt-n1" v-else-if="[7].includes(field.type)">
                    <template v-if="['between'].includes((field.condition && field.condition.value) ? field.condition.value : field.condition)">
                      <v-layout row align-center class="ma-0" key="between">
                        <v-flex xs6 class="pr-2">
                          <v-text-field dense hide-details slot="activator" v-model="field.start" placeholder="HH:mm">
                            <template #append>
                              <v-dialog v-model="field.startTimeMenu" max-width="320">
                                <template #activator="{ on, attrs }">
                                  <v-icon size="22" class="pb-1" v-on="on" v-bind="attrs"> mdi-clock-outline </v-icon>
                                </template>
                                <v-time-picker color="primary" v-model="field.start" format="24hr" @click:minute="field.startTimeMenu = false" full-width></v-time-picker>
                              </v-dialog>
                            </template>
                          </v-text-field>
                        </v-flex>
                        <v-flex xs6 class="pl-2">
                          <v-text-field dense hide-details slot="activator" v-model="field.end" placeholder="HH:mm">
                            <template #append>
                              <v-dialog v-model="field.endTimeMenu" max-width="320">
                                <template #activator="{ on, attrs }">
                                  <v-icon size="22" class="pb-1" v-on="on" v-bind="attrs"> mdi-clock-outline </v-icon>
                                </template>
                                <v-time-picker color="primary" v-model="field.end" format="24hr" @click:minute="field.endTimeMenu = false" full-width></v-time-picker>
                              </v-dialog>
                            </template>
                          </v-text-field>
                        </v-flex>
                      </v-layout>
                    </template>
                    <template v-else>
                      <v-text-field dense hide-details slot="activator" v-model="field.inputValue" placeholder="HH:mm" key="timepicker"
                      @keydown.enter="applyFilters">
                        <template #append>
                          <v-dialog v-model="field.timeMenu" max-width="320">
                            <template #activator="{ on, attrs }">
                              <v-icon size="22" class="pb-1" v-on="on" v-bind="attrs"> mdi-clock-outline </v-icon>
                            </template>
                            <v-time-picker color="primary" v-model="field.inputValue" format="24hr" @click:minute="field.timeMenu = false" full-width></v-time-picker>
                          </v-dialog>
                        </template>
                      </v-text-field>
                    </template>
                  </div>
                </v-flex>
              </v-layout>
            </template>
          </div>
        </v-form>
      </template>
      <!-- <template #append>
        <v-divider></v-divider>
        <v-card class="pa-3 text-center" :style="`background: ${ $vuetify.theme.dark ? '#181818' : '#fafafa' }`">
          <v-btn color="error" class="mr-2 text-capitalize" outlined @click="resetFilters"> {{ $t('reset') }} </v-btn>
          <v-btn color="success" class="text-capitalize" @click="applyFilters"> {{ $t('apply') }} </v-btn>
        </v-card>
      </template> -->
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      drawer: false,
      allfields: [],
      callback: null,
      checkboxFilterItems: [{
        label: this.$t('selected'),
        value: true
      }, {
        label: this.$t('notSelected'),
        value: false
      }]
    }
  },
  components: {
    'datetime-picker': () => import('@/components/DateTimePicker.vue')
  },
  mounted () {
    this.$root.$on('filters', ({ fields, callback = () => {} }) => {
      this.drawer = true
      if (!this.userDetails.isadmin && fields) fields = fields.filter((field) => (field.name !== 'create_for' && (field.allValues ? (field.allValues.label !== 'Event_create_for') : true)))
      this.allfields = this.$formatter.cloneVariable([...fields])
      this.allfields.forEach((x) => {
        if (x.type === 6 && x.date && ['before', 'after'].includes(x.condition)) {
          x.date = this.$formatter.formatDate(x.date, 'YYYY-MM-DDTHH:mm:ss', this.userDetails.dateformat)
        }
        if (x.type === 6 && x.condition === 'between') {
          if (x.startdate) x.startdate = x.startdate ? this.$formatter.formatDate(x.startdate, 'YYYY-MM-DDTHH:mm:ss', this.userDetails.dateformat) : null
          if (x.enddate) x.enddate = x.startdate ? this.$formatter.formatDate(x.enddate, 'YYYY-MM-DDTHH:mm:ss', this.userDetails.dateformat) : null
        }
      })
      this.callback = callback
    })
  },
  computed: {
    ...mapGetters(['getUsers', 'userDetails'])
  },
  methods: {
    updateStartEndDate (index) {
      this.$set(this.allfields[index], 'inputStartDate', this.$formatter.formatDate(this.allfields[index].startdate, 'YYYY-MM-DDTHH:mm', `${this.userDetails.dateformat} HH:mm`))
      this.$set(this.allfields[index], 'inputEndDate', this.$formatter.formatDate(this.allfields[index].enddate, 'YYYY-MM-DDTHH:mm', `${this.userDetails.dateformat} HH:mm`))
      this.$set(this.allfields[index], 'startdate', this.$formatter.formatDate(this.allfields[index].startdate, 'YYYY-MM-DDTHH:mm', 'YYYY-MM-DDTHH:mm:ss'))
      this.$set(this.allfields[index], 'enddate', this.$formatter.formatDate(this.allfields[index].enddate, 'YYYY-MM-DDTHH:mm', 'YYYY-MM-DDTHH:mm:ss'))
    },
    updateDateTime (index) {
      this.$set(this.allfields[index], 'inputDate', this.$formatter.formatDate(this.allfields[index].date, 'YYYY-MM-DDTHH:mm', `${this.userDetails.dateformat} HH:mm`))
      this.$set(this.allfields[index], 'date', this.$formatter.formatDate(this.allfields[index].date, 'YYYY-MM-DDTHH:mm', 'YYYY-MM-DDTHH:mm:ss'))
    },
    getListOfFilters ({ type }) {
      let filterMenuItems = [{
        text: this.$t('is'),
        value: 'is'
      }, {
        text: this.$t('isNot'),
        value: 'isnot'
      }]

      switch (type) {
        case 1:
        case 2:
        case 12:
        case 14:
        case 18:
          filterMenuItems = [...filterMenuItems, {
            text: this.$t('contains'),
            value: 'contains'
          }, {
            text: this.$t('startsWith'),
            value: 'startswith'
          }, {
            text: this.$t('endsWith'),
            value: 'endswith'
          }]
          break
        case 4: // radio
        case 5: // select
        case 15: // user-field
          filterMenuItems = [{
            text: this.$t('isIn'),
            value: 'is'
          }, {
            text: this.$t('isNotIn'),
            value: 'isnot'
          }]
          break
        case 7: // time
          filterMenuItems = [...filterMenuItems, {
            text: this.$t('isBefore'),
            value: 'before'
          }, {
            text: this.$t('isAfter'),
            value: 'after'
          }, {
            text: this.$t('isBetween'),
            value: 'between'
          }]
          break
        case 6: // date
        case 16: // datetime
          filterMenuItems = [{
            text: this.$t('today'),
            value: 'today'
          }, {
            text: this.$t('thisWeek'),
            value: 'thisweek'
          }, {
            text: this.$t('lastWeek'),
            value: 'lastweek'
          }, {
            text: this.$t('thisMonth'),
            value: 'thismonth'
          }, {
            text: this.$t('lastMonth'),
            value: 'lastmonth'
          }, {
            text: this.$t('isBefore'),
            value: 'before'
          }, {
            text: this.$t('isAfter'),
            value: 'after'
          }, {
            text: this.$t('isBetween'),
            value: 'between'
          }, {
            text: this.$t('withinPast'),
            value: 'withinpast'
          }, {
            text: this.$t('withinNext'),
            value: 'withinnext'
          }]
          break
        case 13:
          filterMenuItems = [{
            text: this.$t('equalTo'),
            value: 'equalto'
          }, {
            text: this.$t('notequal'),
            value: 'notequalto'
          }, {
            text: this.$t('lessThan'),
            value: 'lessthan'
          }, {
            text: this.$t('lessThanEqualTo'),
            value: 'lessthanorequalto'
          }, {
            text: this.$t('greaterThan'),
            value: 'greaterthan'
          }, {
            text: this.$t('greaterThanEqualTo'),
            value: 'greaterthanorequalto'
          }]
          break
      }
      return filterMenuItems
    },
    fieldCheckChangeHandler ({ field, fieldIndex } = {}) {
      if (!field.selectBox) return
      this.allfields[fieldIndex].condition = this.getSelectedType({ field, returnValue: 'value' })
    },
    getSelectedType ({ field, returnValue = 'text' } = {}) {
      if (field.condition && field.condition[returnValue]) return field.condition[returnValue]
      const filters = this.getListOfFilters(field)

      var result = filters.find(x => x.value === field.condition)
      if (result && result[returnValue]) return result[returnValue]
      return filters[0][returnValue] || filters[0]
    },
    getSelectFieldItemTextValue (field) {
      if (!field.default_value) return { text: 'label', value: 'value' }
      if ((field.default_value && field.default_value.selectType === 'module') || field.type === 15) return { text: 'data.name', value: '_id' }
      return { text: (val) => this.$t(val.label), value: 'value' }
    },
    async loadSelectFieldValues ({ field, fieldIndex }) {
      const searchTerm = this.$refs.filterSearchText[0].lazySearch
      if (!searchTerm) return
      const modulename = field.type === 15 ? 'Account' : (field.isSubModule ? field.allValues.module : field.default_value.selectedModule)
      await this.$api.execute('get', `moduledata/${modulename}/search?searchterm=${searchTerm}`)
        .then(({ data }) => {
          if (data) this.$set(field.default_value, 'options', data)
        })
    },
    applyFilters () {
      const conditions = []
      for (const field of this.allfields) {
        if (field.selectBox) {
          const checkCondition = (field.condition && field.condition.value) ? field.condition.value : field.condition
          if (field.type === 6 && ['before', 'after'].includes(checkCondition)) {
            field.date = this.$formatter.formatDate(field.date, this.userDetails.dateformat, 'YYYY-MM-DDTHH:mm:ss')
          }
          if (field.type === 6 && checkCondition === 'between') {
            if (field.startdate) field.startdate = field.startdate ? this.$formatter.formatDate(field.startdate, this.userDetails.dateformat, 'YYYY-MM-DDTHH:mm:ss') : null
            if (field.enddate) field.enddate = field.startdate ? this.$formatter.formatDate(field.enddate, this.userDetails.dateformat, 'YYYY-MM-DDTHH:mm:ss') : null
          }
          conditions.push({
            ...field,
            selectBox: field.selectBox,
            field: field._id || field.field,
            fieldtype: field.type,
            condition: ((field.condition && field.condition.value)
              ? field.condition.value
              : (field.condition || this.getListOfFilters(field)[0].value)),
            selected: ((field.condition && field.condition.value)
              ? field.condition.value
              : (field.condition || this.getListOfFilters(field)[0].value)),
            inputValue: this.$formatter.isNullOrEmpty(field.inputValue) ? null : field.inputValue,
            value: field.value
          })
        }
      }
      // this.saveOrUpdateFilters({ conditions, remove: conditions.length === 0 })
      this.callback(conditions.length ? conditions : null)
      this.drawer = false
      // if (this.$refs.filterform && this.$refs.filterform.validate()) {
      // } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'validationIssue' })
    },
    resetFilters () {
      for (const fieldIndex in this.allfields) {
        const element = this.$formatter.cloneVariable(this.allfields[fieldIndex])
        element.selectBox = false
        element.inputValue = ''
        element.condition = null
        switch (element.type) {
          case 6: element.selected = 'today'
            element.date = null
            element.startDateinput = null
            element.startDatePicked = null
            element.endDateinput = null
            element.startdate = null
            element.enddatePicker = null
            element.enddate = null
            element.within = null
            break
          case 7: element.selected = 'is'
            element.endTimeValue = null
            element.startTimeValue = null
            element.timeValue = null
            element.start = null
            element.end = null
            break
          case 16: element.selected = 'today'
            element.date = null
            element.dateTimePicker = null
            element.timePicker = null
            element.inputStartDate = null
            element.inputEndDate = null
            element.inputDate = null
            element.dateTimeStartPicker = null
            element.dateTimeEndPicker = null
            element.startdatePicker = null
            element.startDatePicked = null
            element.endDatePicked = null
            element.startdate = null
            element.enddatePicker = null
            element.endTimePicker = null
            element.startTimePicker = null
            element.enddate = null
            element.within = null
            break
          default: element.selected = 'is'
        }
        // this.allfields[fieldIndex].selectBox = false
        this.$set(this.allfields, fieldIndex, element)
      }
      // this.saveOrUpdateFilters({ remove: true })
      this.callback(false)
      this.$eventBus.$emit('resetFilter', this.allfields)
      this.drawer = false
      // this.callback(false)
      // this.drawer = false
    },
    // saveOrUpdateFilters ({ conditions, remove }) {
    //   // const filtersHistory = this.$formatter.cloneVariable(this.getListOfFiltersHistory)
    //   let filtersHistory = localStorage.getItem('BusinessFlow365_filtersHistory')
    //   filtersHistory = filtersHistory ? JSON.parse(filtersHistory) : []

    //   const alreadyStoredIndex = filtersHistory.findIndex((filtersHistoryItem) => filtersHistoryItem.moduleID === this.$route.params.id)
    //   if (remove) {
    //     if (alreadyStoredIndex >= 0) filtersHistory.splice(alreadyStoredIndex, 1)
    //   } else {
    //     if (alreadyStoredIndex >= 0) filtersHistory[alreadyStoredIndex].conditions = conditions
    //     else filtersHistory.push({ name: this.$route.params.name, moduleID: this.$route.params.id, conditions })
    //   }

    //   localStorage.setItem('BusinessFlow365_filtersHistory', JSON.stringify(filtersHistory))
    //   if (remove) {
    //     this.callback(false)
    //     this.$eventBus.$emit('resetFilter', this.allfields)
    //     this.drawer = false
    //   }
    // },
    preventSpecialCharacters (e) {
      if (/^\W$/.test(e.key)) {
        e.preventDefault()
      }
    }
  },
  beforeDestroy () {
    this.$root.$off('filters')
  }
}
</script>
<style scoped>
  .filters-body-container::-webkit-scrollbar {
    display: none !important;
  }
</style>
